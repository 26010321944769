import { useQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import { GET_USERS } from 'graphql/queries';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IListProps } from './interface';

export const AdminPageViewModel = () => {
  // const [filter, setFilter] = useState(false);
  const filter = false;
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [limit, setLimit] = useState(5);
  const [value, setValue] = useState(0);
  const { data } = useQuery(GET_USERS, {
    variables: {
      listUsersInput: {
        pagination: {
          limit,
          page: page + 1,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  // const handleFilterChange = () => {
  //   setFilter(!filter);
  // };

  useEffect(() => {
    if (data) {
      setUsers(
        data.listUsers.users.map((list: IListProps) => ({
          id: list.id,
          name: `${list.firstName} ${list.lastName}`,
          email: list.email,
          createdAt: list.createdAt,
          serviceLine: list.serviceLine.name,
          role: list.roles.map(role => `${role.name} `),
          reviewer: list.reviewer ? `${list.reviewer?.firstName} ${list.reviewer?.lastName}` : '-',
        }))
      );
      setCount(data.listUsers.count);
    }
  }, [data]);

  const columns = [
    {
      title: 'User ID',
      field: 'id',
      sorting: false,
    },
    {
      title: 'Name',
      field: 'name',
      sorting: false,
    },
    {
      title: 'Email',
      field: 'email',
      sorting: false,
    },
    {
      title: 'Date Added',
      field: 'createdAt',
      sorting: false,
    },
    {
      title: 'Role',
      field: 'role',
      sorting: false,
    },
    {
      title: 'Service Line',
      field: 'serviceLine',
      sorting: false,
    },
    {
      title: 'Reviewer',
      field: 'reviewer',
      sorting: false,
    },
    {
      title: 'Action',
      field: 'id',
      align: 'center',
      sorting: false,
      // eslint-disable-next-line no-unused-vars
      render: (row: { status: string; id: string }) => (
        <>
          <Button to="/" component={Link} color="primary" style={{ fontWeight: 'bold' }}>
            Edit
          </Button>
          <Button to="/" component={Link} style={{ fontWeight: 'bold' }} color="primary">
            Delete
          </Button>
        </>
      ),
    },
  ];

  const title = 'List of Users';

  return {
    value,
    users,
    filter,
    data,
    columns,
    title,
    // handleFilterChange,
    page,
    count,
    limit,
    setLimit,
    setPage,
    setValue,
  };
};
