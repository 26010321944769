import DashboardLayout from 'components/DashboardLayout';
import Table from 'components/Table';
import SelectTab from 'pages/AdminPage/components/SelectTab';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { AdminDocumentListPageViewModel } from './AdminDocumentListPage.ViewModel';

const AdminDocumentListPage: FC<RouteComponentProps> = () => {
  const vm = AdminDocumentListPageViewModel();

  return (
    <div>
      <DashboardLayout>
        <Table
          title={vm.title}
          columns={vm.columns}
          data={vm.documents}
          hideFilterCheckbox
          disableButton
          // filter={vm.filter}
          // handleFilterChange={vm.handleFilterChange}
          setPage={vm.setPage}
          setLimit={vm.setLimit}
          pageNum={vm.page}
          count={vm.count}
          selectTab={<SelectTab value={vm.value} setValue={vm.setValue} />}
        />
      </DashboardLayout>
    </div>
  );
};

export default AdminDocumentListPage;
