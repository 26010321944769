import DashboardLayout from 'components/DashboardLayout';
import Table from 'components/Table';
import { ADMIN_CREATE_USER_PAGE_PATH } from 'helper/constants';
import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { AdminPageViewModel } from './AdminPage.ViewModel';

import SelectTab from './components/SelectTab';

const AdminPage: FC<RouteComponentProps> = () => {
  const vm = AdminPageViewModel();

  return (
    <div>
      <DashboardLayout>
        <Table
          title={vm.title}
          columns={vm.columns}
          data={vm.users}
          hideFilterCheckbox
          // filter={vm.filter}
          // handleFilterChange={vm.handleFilterChange}
          createButtonLabel="Create New User"
          createButtonRoutePath={ADMIN_CREATE_USER_PAGE_PATH}
          setPage={vm.setPage}
          setLimit={vm.setLimit}
          pageNum={vm.page}
          count={vm.count}
          selectTab={<SelectTab value={vm.value} setValue={vm.setValue} />}
        />
      </DashboardLayout>
    </div>
  );
};

export default AdminPage;
