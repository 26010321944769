/* eslint-disable react/jsx-no-bind */
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { ADMIN_DOCUMENTS_PAGE_PATH, ADMIN_USERS_PAGE_PATH } from 'helper/constants';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    marginBottom: '10px',
  },
});

export default function SelectTab({ value, setValue }) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
        <Tab to={ADMIN_USERS_PAGE_PATH} component={Link} label="User List" />
        <Tab to={ADMIN_DOCUMENTS_PAGE_PATH} component={Link} label="PA Doument List" />
      </Tabs>
    </Paper>
  );
}
